import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import routesMap from '../../../Routes';

import sofa from '../../../assets/tilliUptrade/sofa.jpg';
import sofaSeat from '../../../assets/tilliUptrade/sofaSeat.jpg';
import sofaBottom from '../../../assets/tilliUptrade/sofaBottom.jpg';
import backgroundImage from '../../../assets/tilliUptrade/backgroundImage.svg';

import { Button as StyledButton, FlexContainer as StyledFlexContainer } from './styledComponents';

const MainContainer = styled.div`
  margin-bottom: 97px;
  padding: 0px 0px 474px;
  position: relative;
  width: 100%;
  justify-content: center;
  display: flex;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: 0px;
    margin-bottom: 70px;
    flex-direction: column;
  }
`;

const HeaderTopBlock = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
  justify-content: center;
  
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    flex-direction: column-reverse;
    margin-bottom: 70px;
  }
`;

const HeaderTextBlock = styled.div`
  padding: 0px 81px 74px 122px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
    padding: 0px 18px;
    box-sizing: border-box;
  }
`;

const SubTitle = styled.p`
  height: 23px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  line-height: 23px;
  color: ${({ theme }) => theme.colors.navy};
  display: flex;
  text-transform: uppercase;
  margin-bottom: 52px;
  letter-spacing: 6.5px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 3.8px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
`;

const Text = styled.div`
  margin-bottom: 60px;
  max-width: 485px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    max-width: unset;
    width: 100%;
    margin-bottom: 35px;
  }
`;

const Title = styled.p`
  width: 100%;
  margin-bottom: 20px;
  font-family: Libre Baskerville;
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  color: ${({ theme }) => theme.colors.navy};

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 23px;
    line-height: 34px;
    margin-bottom: 15px;
  }
`;

const Description = styled.p`
  width: 100%;
  font-family: Libre Baskerville;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: ${({ theme }) => theme.colors.navy};
  display: flex;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 21px;
  }
`;

const HeaderImage = styled.img`
  max-width: 752px;
  height: 258px;
  margin-bottom: 31px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    max-width: unset;
    width: 100%;
    height: auto;
    min-height: 110px;
    margin-bottom: 13px;
  }
`;

const HeaderImageSmall = styled.img`
  height: 260px;
  ${({ isLeft }) => isLeft
    ? `
      flex: 1;
      margin-right: 31px;
    ` : 'width: 61%;'}
  object-fit: cover;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    height: 111px;
    ${({ isLeft }) => isLeft && 'margin-right: 13px;'}
  }
`;

const BackgroundImage = styled.img`
  width: 646.5px;
  height: 386px;
  position: absolute;
  left: 0px;
  bottom: 276px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    display: none;
  }
`;

const HeaderBottomBlockText = styled.p`
  background-color: ${({ theme }) => theme.colors.lightGrey7};
  padding: 94px 289px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0px;
  bottom: 0px;
  flex: 1;
  text-align: center;
  font-family: Libre Baskerville;
  font-size: 26px;
  font-weight: 400;
  line-height: 39px;
  color: ${({ theme }) => theme.colors.navy};
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: 57px 18px;
    font-size: 18px;
    line-height: 27px;
    position: relative;
  }
`;

const FlexContainer = styled(StyledFlexContainer)`
  ${({ isFull }) => isFull && 'width: 100%;'}
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
  }
`;

const Italic = styled.span`
  font-style: italic;
`;

const Button = styled(StyledButton)`
  z-index: 1;
`;

const HomeHeader = () => (
  <MainContainer>
    <HeaderTopBlock>
      <HeaderTextBlock>
        <SubTitle>Partenaire Uptrade</SubTitle>
        <Text>
          <Title>
            Vos tissus pour donner
            {' '}
            <Italic>vie</Italic>
            {' à vos idées sur mesure'}
          </Title>
          <Description>
            Besoin de tissus pour donner une seconde vie à vos vêtements ou éléments de décoration ? Découvrez notre sélection et revalorisez des stocks dormants.
          </Description>
        </Text>
        <Button isNavy style={{ alignSelf: 'center' }} onClick={() => navigate(routesMap.ProductsSelectorPage.url)}>Chercher du tissu</Button>
      </HeaderTextBlock>
      <FlexContainer column>
        <HeaderImage alt="House de canapé" src={sofa} />
        <FlexContainer isFull>
          <HeaderImageSmall isLeft alt="Housse d'assise de canapé" src={sofaSeat} />
          <HeaderImageSmall alt="Bas de housse de canapé" src={sofaBottom} />
        </FlexContainer>
      </FlexContainer>
    </HeaderTopBlock>
    <BackgroundImage alt="Courbe" src={backgroundImage} />
    <HeaderBottomBlockText>
      “Lutter contre le gaspillage textile et soutenir une économie de la mode plus circulaire en réutilisant ce qui existe déjà”
      <br />
      <Italic>- Uptrade</Italic>
    </HeaderBottomBlockText>
  </MainContainer>
);

export default HomeHeader;
