import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Home from '../../components/home/TilliUptrade/Home';
import Layout from '../../layouts/index';
import withUptradeContext from '../../withUptradeContext';

const HomePageTilliUptrade = ({ uptradeContext: { apiPartnerProducts, fetchApiPartnerProducts }, ...props }) => {
  useEffect(() => {
    if (!(apiPartnerProducts?.length > 0)) { fetchApiPartnerProducts(); }
  }, []);

  return (
    <Layout routeSlug="HomePageTilliUptrade">
      <Home {...props} altTag="retouche couture avec Tilli" />
    </Layout>
  );
};

HomePageTilliUptrade.propTypes = {
  uptradeContext: PropTypes.shape({
    apiPartnerProducts: PropTypes.arrayOf(PropTypes.shape({})),
    partnerProducts: PropTypes.arrayOf(PropTypes.shape({})),
    fetchApiPartnerProducts: PropTypes.func,
  }).isRequired,
};

export default withUptradeContext(HomePageTilliUptrade);
