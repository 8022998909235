import React from 'react';
import styled from 'styled-components';

import './style.css';

import { WhiteBlock, Button as StyledButton, FlexContainer } from './styledComponents';
import callApi from '../../../services/api';
import { isEmailValid } from '../../../services/checkingFormat';

const Title = styled.p`
  height: 40px;
  font-family: Libre Baskerville;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  color: ${({ theme }) => theme.colors.navy};
  display: flex;
  text-align: center;
  margin-bottom: 78px;
  letter-spacing: 2px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 45px;
  }
`;

const SubscribeInput = styled.input`
  margin-right: 25px;
  max-width: 370px;
  border: 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.navy};
  padding: 0px 182px 11px 3px;
  position: relative;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.18px;
  &::placeholder {
    color: ${({ theme }) => theme.colors.silver2};
  }
  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    margin-right: 0px;
    margin-bottom: 30px;
    max-width: unset;
  }
`;

const InfoText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
  color: ${({ isErrorText }) => isErrorText ? ({ theme }) => theme.colors.red : ({ theme }) => theme.colors.navy};
  width: 100%;
  margin: 0px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 12px;
    line-height: 22px;
    text-align: center;
  }
`;

const FormContainer = styled(FlexContainer)`
  align-items: flex-end;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    flex-direction: column;
    width: 100%;
  }
`;

const Button = styled(StyledButton)`
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    align-self: center;
  }
`;

class HomeNewsletter extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      isValid: true,
      hasSubscribed: false,
    };
  }

  setInputValue = (event) => {
    const { target } = event;

    this.setState({
      [target.name]: target.value,
      isValid: true,
    });
  }

  registerToNewsletter = () => {
    const { email } = this.state;

    if (!isEmailValid(email)) {
      this.setState({ isValid: false });
    } else {
      callApi('/public/registerToNewsletter', 'POST', { email }).then(() => this.setState({ email: '', hasSubscribed: true }));
    }
  }

  render() {
    const { email, hasSubscribed, isValid } = this.state;
    return (
      <WhiteBlock>
        <Title>Recevez toutes nos actus </Title>
        <FlexContainer column>
          <FormContainer>
            <SubscribeInput
              type="email"
              placeholder="exemple@exemple.fr"
              value={email}
              name="email"
              onChange={this.setInputValue}
              isValid={isValid}
            />
            <Button onClick={this.registerToNewsletter}>S’inscrire</Button>
          </FormContainer>
          {hasSubscribed && <InfoText>Votre inscription a bien été prise en compte</InfoText>}
          {!isValid && <InfoText isErrorText>Adresse non valide</InfoText>}
        </FlexContainer>
      </WhiteBlock>
    );
  }
}

export default HomeNewsletter;
