import React from 'react';
import styled from 'styled-components';

import { Container, Content } from '../v2/styledComponents';
import MenuBar from '../MenuBar';
import HomeHeader from './HomeHeader';
import HomeProject from './HomeProject';
import HomeRevaluation from './HomeRevaluation';
import HomeNewsletter from './HomeNewsletter';
import Footer from '../v3/Footer';

import './style.css';

const TilliUptradeIntro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 105px;
  margin-bottom: 82px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-top: 0px;
    margin-bottom: 40px;
  }
`;

const Home = () => (
  <Container>
    <Content full>
      <MenuBar />
      <TilliUptradeIntro>
        <HomeHeader />
        <HomeProject />
        <HomeRevaluation />
        <HomeNewsletter />
      </TilliUptradeIntro>
      <Footer />
    </Content>
  </Container>
);

export default Home;
