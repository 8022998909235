// cSpell:ignore lazyload
import React from 'react';
import styled from 'styled-components';

import bag from '../../../assets/tilliUptrade/bag.jpg';
import plaid from '../../../assets/tilliUptrade/plaid.jpg';

import { WhiteBlock, FlexContainer } from './styledComponents';

const ProjectTitleBlock = styled.div`
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: 40px;
  }
`;

const ProjectSubTitle = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.navy};
  display: flex;
  text-align: center;
  margin-bottom: 15px;
  letter-spacing: 5.5px;
  text-transform: uppercase;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 10px;
    margin-bottom: 10px;
  }
`;

const ProjectTitle = styled.p`
  font-family: Libre Baskerville;
  font-size: 32px;
  font-weight: 700;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.navy};
  display: flex;
  text-align: center;
  letter-spacing: 2px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 20px;
  }
`;

const ProjectsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    flex-direction: column;
    width: 100%;
  }
`;

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:not(:last-of-type) {
    margin-right: 110px;
  }

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
    &:not(:last-of-type) {
      margin-right: 0px;
      margin-bottom: 50px;
    }
  }
`;

const Image = styled.img`
  width: 529px;
  height: 413px;
  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }
`;

const Line = styled.div`
  width: 24px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.navy};
  margin-top: 15px;
  margin-right: 8px;
  margin-left: 4px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 15px;
    margin-top: 11px;
    margin-left: 0px;
  }
`;

export const ServiceTitle = styled.p`
  width: 100%;
  font-family: Libre Baskerville;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.navy};
  margin-bottom: 14px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 22px;
  }
`;

export const ServiceDescription = styled.p`
  max-width: 420px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.navy};

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 12px;
    line-height: 14px;
  }
`;

const HomeProject = () => (
  <WhiteBlock>
    <ProjectTitleBlock>
      <ProjectSubTitle>L’achat de tissu</ProjectSubTitle>
      <ProjectTitle>Pour quel projet ?</ProjectTitle>
    </ProjectTitleBlock>
    <ProjectsContainer>
      <ProjectContainer>
        <Image alt="Sac en tissu Tilli" src={bag} />
        <FlexContainer>
          <Line />
          <FlexContainer column>
            <ServiceTitle>Création d’accessoires</ServiceTitle>
            <ServiceDescription>
              Co-créez avec l’un de nos Tillistes l’accessoire de vos rêves, à partir de chutes de tissus ou d’anciens vêtements.
            </ServiceDescription>
          </FlexContainer>
        </FlexContainer>
      </ProjectContainer>
      <ProjectContainer>
        <Image alt="Plaid en tissu Tilli" src={plaid} />
        <FlexContainer>
          <Line />
          <FlexContainer column>
            <ServiceTitle>Création de décorations</ServiceTitle>
            <ServiceDescription>
              Donnez une seconde vie à votre mobilier et embellissez vos intérieurs de façon responsable avec votre Tilliste.
            </ServiceDescription>
          </FlexContainer>
        </FlexContainer>
      </ProjectContainer>
    </ProjectsContainer>
  </WhiteBlock>
);

export default HomeProject;
